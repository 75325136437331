import React from 'react';
import {
    Grid, Box, Typography,
} from '@mui/material';
import { Link } from 'gatsby';
import colors from '../../theme/colors';

const centeredFlexWrapper = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    flexDirection: 'column',
};

const centeredFlexContainer = {
    height: 'auto',
    maxWidth: '281px',
    width: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
};

const FunctionalityAndCasesContainer = ({ casesOfUseData }) => {
    const infoWrapper = {
        padding: casesOfUseData.customPadding ? casesOfUseData.customPadding : '40px 100px 73px 100px',
        minHeight: 'auto',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        alignItems: 'center',
        maxWidth: '1440px',
        width: '100%',
    };
    const customContainerPadding = casesOfUseData.customContainerPadding ? casesOfUseData.customContainerPadding : '48px 24px';

    return (
        <Box
            style={{
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: colors.darkViolet,
            }}
        >
            <Box
                sx={(theme) => ({ [theme.breakpoints.down('md')]: { padding: customContainerPadding }, ...infoWrapper })}
            >
                {casesOfUseData?.title && (
                    <Box
                        sx={(theme) => ({
                            [theme.breakpoints.down('md')]: { paddingBottom: '32px' },
                            textAlign: 'center',
                            width: '100%',
                            paddingBottom: '40px',
                        })}
                    >
                        <Typography
                            variant="sectionH2"
                            style={{ fontSize: '30px' }}
                        >
                            {casesOfUseData.title}
                        </Typography>
                        {casesOfUseData?.subTitle && (
                            <Typography variant="h3" style={{ fontWeight: 600, lineHeight: '38.4px' }}>
                                {casesOfUseData.subTitle}
                            </Typography>
                        )}
                    </Box>
                )}
                <Grid
                    container
                    align="center"
                    columns={casesOfUseData.gridColumns}
                    style={casesOfUseData.maxWidth && { ...casesOfUseData.maxWidth }}
                >
                    {casesOfUseData.cases.map((c) => (
                        <Grid
                            item
                            xs={casesOfUseData.gridColumns}
                            md={casesOfUseData.maxRowGridSize}
                            key={c.text}
                            sx={(theme) => ({
                                [theme.breakpoints.down('md')]: { margin: '10px 0px 10px' },
                                ...centeredFlexWrapper,
                                justifyContent: 'space-between',
                            })}
                        >
                            <Box sx={{ ...centeredFlexContainer }}>
                                <Box
                                    sx={{
                                        display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '25px',
                                    }}
                                    style={casesOfUseData.customImageHeight ? casesOfUseData.customImageHeight : { height: '84px' }}
                                >
                                    {c.image}
                                </Box>
                                <Typography variant="h4" style={{ maxWidth: '201px', fontWeight: 600, lineHeight: '35px' }}>
                                    {c.text}
                                </Typography>
                                {c.subTitleLink && (
                                    <Typography
                                        component={Link}
                                        to={c.subTitleLinkUrl}
                                        variant="h4"
                                        style={{
                                            fontWeight: 600,
                                            lineHeight: '19px',
                                            color: colors.green,
                                            fontSize: '13px',
                                            textDecoration: 'none',
                                        }}
                                    >
                                        {c.subTitleLink}
                                    </Typography>
                                )}
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Box>
    );
};

export default FunctionalityAndCasesContainer;

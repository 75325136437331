import React from 'react';
import {
    Typography, Box, Button, Grid,
} from '@mui/material';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import Layout from '../../layout/Layout';
import ServiceIntroduction from '../../components/services/ServiceIntroduction';
import HomeConsultation from '../../components/home/HomeConsultation';
import FunctionalityAndCasesContainer from '../../components/services/FunctionalityAndCasesContainer';
import colors from '../../theme/colors';

const infoWrapper = {
    maxWidth: '1440px',
    width: '100%',
    padding: '20px 136px 0px',
    minHeight: '284px',
};
const customTextTypography = {
    fontFamily: 'Nunito',
    fontSize: '22px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '39px',
    letterSpacing: '0em',
    textAlign: 'left',
};
const customCircleRoundedIcon = {
    color: colors.lightGreen,
    fontSize: 'small',
    transform: 'scale(0.75)',
    marginRight: '8px',
};
const violetContainer = {
    padding: '37px 37px 31px 37px',
    minHeight: '258px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'start',
    background: `linear-gradient(180deg, #26192C -31.93%, ${colors.violet} 54.16%)`,
    border: '2px solid transparent',
    borderRadius: '10px',
};
const arrowIcon = {
    color: colors.green,
    fontSize: 'medium',
    marginLeft: '8px',
    fontWeight: 'light',
};

const serviceIntroductionData = {
    title: 'Private Cloud',
    firstParagraph: 'Servicio de infraestructura en alta disponibilidad, dedicado únicamente para vos. Mantené el SLA y las virtudes de la nube pública, sin compartir recursos con otros clientes y administrado por nuestros ingenieros',
    gatsbyImg: (<StaticImage src="../../images/private-cloud.svg" alt="Nube privada" />),
    isButton: true,
    buttonText: 'Contactanos',
    buttonLinkTo: '/contact_us',
};

const casesOfUseData = {
    title: 'Definimos en conjunto',
    cases: [
        {
            image: (<LayersOutlinedIcon style={{ color: colors.green, height: '107px', width: '107px' }} />),
            text: 'Plataforma de virtualización',
        },
        {
            image: (<StaticImage src="../../images/leaf_icon.svg" alt="Escalabilidad" />),
            text: 'Hardware, recursos, redundancia y plan de escalabilidad',
        },
        {
            image: (<CompareArrowsIcon style={{ color: colors.green, height: '96px', width: '96px' }} />),
            text: 'Políticas de backup',
        },
        {
            image: (<StaticImage src="../../images/velocity_icon.svg" alt="Ancho de banda" />),
            text: 'Ancho de banda y rango de IPs públicas',
        },
    ],
    gridColumns: 12,
    maxRowGridSize: 3,
    customPadding: '50px 136px 41px',
};

const PrivateCloud = () => (
    <Layout>
        <ServiceIntroduction serviceIntroductionData={serviceIntroductionData} />
        <FunctionalityAndCasesContainer casesOfUseData={casesOfUseData} />

        <Box
            style={{
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: colors.darkViolet,
            }}
        >
            <Box sx={(theme) => ({ [theme.breakpoints.down('sm')]: { padding: '0px 24px' }, ...infoWrapper })}>
                <Grid container>
                    <Grid
                        item
                        xs={12}
                        lg={8}
                        sx={(theme) => ({
                            [theme.breakpoints.up('lg')]: { paddingRight: '90px' },
                            [theme.breakpoints.down('md')]: { paddingBottom: '20px' },
                        })}
                    >
                        <Box sx={{ ...violetContainer }}>
                            <Typography sx={{ ...customTextTypography }} mb={1}>
                                Servicios relacionados
                            </Typography>
                            <Typography variant="h5" style={{ lineHeight: '32px' }}>
                                Recomendado para ambientes SAP. Podemos incluir licencias SLES (SUSE Linux Enterprise Server), plataforma recomendada para ambientes SAP HANA.
                            </Typography>
                            <Button
                                component={Link}
                                to="/solutions/sap"
                                aria-label="Ver más"
                                sx={{ textDecoration: 'none', marginLeft: '-8px' }}
                            >
                                <Typography variant="h5">
                                    Ver más
                                    <DoubleArrowIcon sx={{ ...arrowIcon }} />
                                </Typography>
                            </Button>
                        </Box>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        lg={4}
                        sx={(theme) => ({
                            [theme.breakpoints.down('lg')]: { padding: '24px 37px 12px' },
                        })}
                    >
                        <Typography sx={{ ...customTextTypography }} mb={1}>
                            Datos de la solución
                        </Typography>
                        <Typography variant="h5" style={{ lineHeight: '32px' }}>
                            <CircleRoundedIcon style={{ ...customCircleRoundedIcon }} />
                            Hardware para virtualización y almacenamiento 100% HPE.
                        </Typography>
                        <Typography variant="h5" style={{ lineHeight: '32px' }}>
                            <CircleRoundedIcon style={{ ...customCircleRoundedIcon }} />
                            Firewall físico o virtual
                        </Typography>
                        <Typography variant="h5" style={{ lineHeight: '32px' }}>
                            <CircleRoundedIcon style={{ ...customCircleRoundedIcon }} />
                            NOC y manos remotas 24/7
                        </Typography>
                        <Typography variant="h5" style={{ lineHeight: '32px' }}>
                            <CircleRoundedIcon style={{ ...customCircleRoundedIcon }} />
                            Administrado por nuestros ingenieros.
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </Box>
        <HomeConsultation />
    </Layout>
);

export default PrivateCloud;
